<template>
      <TransactionDetailsView :back="false" :transaction="transaction" title="Details" />
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
// import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import TransactionDetailsView from '@/views/Transactions/widgets/TransactionDetailsView'

export default {
    name: 'Transactions',
    components: { TransactionDetailsView, AppWrapper },

    setup() {
        const store = useStore()
        const transaction = computed(() => store?.state?.Transaction?.transaction)
        
        return { transaction }
    },
}
</script>

<style scoped></style>
