<!-- eslint-disable -->
<template>
    <div>
        <!-- transaction Comfirmation -->
        <template v-if="!transaction"><Spinner /></template>
        <router-link v-if="back" :to="{ name: 'Transactions' }"> <div class="ml4">&lt; Back</div> </router-link>

        <div
            id="receiptContainer"
            ref="transactionDetailsRef"
            class="box-border mt4 mb4 w-60-l w-100 center mini-spacingx"
            v-if="transaction"
        >
            <div class="w-100 flex justify-between">
                <div class="box-border-bottom b f4-l f5 pa3-l pa3" style="color: #132c8c">Transaction {{ title }}</div>
                <div class="box-border-bottom b f4-l f5 pa3-l pa3">Reference: {{ transaction?.reference }}</div>
            </div>
            <div class="pa4-l pa2" style="background: #fbfcfe">
                <div class="tc pa4" style="background: white">
                    <div class="flex justify-center items-center" v-if="organization?.name">
                        <div ref="companyLogoWrapper" v-if="organization?.imageUrl">
                            <img
                                ref="companyLogo"
                                :src="orgImage"
                                alt=""
                                style="object-position: center; object-fit: cover; width: 70px; height: 70px;"
                                class="rounded-circle"
                            />
                        </div>
                        <div
                            v-else
                            class="flex justify-center items-center font-w3"
                            style="
                                background: rgb(215, 222, 250);
                                height: 50px;
                                width: 50px;
                                border-radius: 50%;
                                font-size: 25px;
                                color: rgb(19, 44, 140);
                            "
                        >
                            <span class="org-initials">{{ orgInitials || userInitials }}</span>
                        </div>
                    </div>
                    <div class="b pv3">{{ organization?.name }}</div>
                    <div class="pv1" v-if="organization?.phoneNumber">{{ organization?.phoneNumber }}</div>
                    <div class="pv1" v-if="organization?.orgEmail">{{ organization?.orgEmail }}</div>
                    <div class="pv1" v-if="organization?.address">{{ organization?.address }}</div>

                    <div class="pt3">
                        <img src="../../../assets/images/Succes-Icons.svg" alt="" />
                        <div class="pv1">Transaction Total</div>
                        <div class="pv2 f4 b">{{ transaction.grandTotal }}</div>
                    </div>
                    <div class="font-w1 f5 pt3">
                        Your <span v-if="transaction.type === 'sales'">Order</span>
                        <span v-else-if="transaction.type === 'purchases'">Purchase</span>
                        <span v-else-if="transaction.type === 'expenses'">Expense</span>
                        <span v-else-if="transaction.type === 'repayment'">Repayment</span>
                        <span v-else-if="transaction.subtype === 'capital'">Investment</span>
                        <span class="ttc" v-else>{{ transaction.subtype }}</span>
                        <span class="pl1">ID: {{ transaction?._id }}</span>
                    </div>
                    <div class="pv1 mt2">{{ new Date(transaction.date || transaction.createdAt).toUTCString() }}</div>
                </div>
                <div style="background: white">
                    <div class="items-warapper pa5-l pa4 font-w1 pb3-l">
                        <div v-for="item of transaction?.items" v-bind:key="item._id">
                            <div class="flex justify-between pv3 box-border-bottom ttc" style="border-top: 1px solid #e3e8ee">
                                <div>
                                    {{
                                        transaction.type === 'sales' || transaction.type === 'purchases'
                                            ? `${item.productName || item.name} (${item.quantity})`
                                            : item.subtype
                                    }}
                                </div>
                                <div>{{ item.amount }}</div>
                            </div>
                        </div>

                        <!-- Sub Total-->
                        <div v-if="transaction.type === 'expenses'">
                            <div class="flex justify-between pv3 box-border-bottom" style="color: #999999">
                                <div>Subtype</div>
                                <div>{{ transaction?.subtype }}</div>
                            </div>
                            <div class="flex justify-between pv3 box-border-bottom" style="color: #999999">
                                <div>Expense type</div>
                                <div>{{ transaction?.expense_type }}</div>
                            </div>
                            <div class="flex justify-between pv3 box-border-bottom" style="color: #999999">
                                <div v-if="transaction?.description">Description</div>
                                <div v-if="transaction?.description">{{ transaction?.description }}</div>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                transaction.type === 'inflows' ||
                                    transaction.type === 'outflows' ||
                                    transaction.type === 'expenses'
                            "
                            class="flex justify-between pv3 box-border-bottom"
                            style="color: #999999"
                        >
                            <div v-if="transaction?.description">Description</div>
                            <div v-if="transaction?.description">{{ transaction?.description }}</div>
                        </div>
                        <div v-else class="flex justify-between pv3 box-border-bottom" style="color: #999999">
                            <div>Subtotal</div>
                            <div>{{ transaction?.subtotal }}</div>
                        </div>

                        <!-- Charges-->

                        <div v-for="item of transaction?.charges" v-bind:key="item._id">
                            <div class="flex justify-between pv2 box-border-bottom" style="color: #999999">
                                <!-- <div class="ttc">{{ item?.subtype }}</div>-->
                                <div class="ttc">
                                    <span class="pr1">{{ item?.subtype }}</span>

                                    <span v-if="item?.basedOn === 'percent'"> ({{ item?.value }} %) </span>
                                </div>
                                <div>{{ item?.amount }}</div>
                            </div>
                        </div>

                        <div class="flex justify-between pv3 box-border-bottom f5 b">
                            <div>Grand Total</div>
                            <div>{{ transaction.grandTotal }}</div>
                        </div>

                        <div class="flex justify-between pv3 box-border-bottom f5">
                            <div class="dark-green">Amount Paid</div>
                            <div class="dark-green">{{ transaction?.paid }}</div>
                        </div>

                        <div v-if="transaction?.credit" class="flex justify-between pv3 box-border-bottom f5">
                            <div class="dark-red">Amount Owed</div>
                            <div class="dark-red">{{ transaction?.owed }}</div>
                        </div>

                        <div v-if="transaction?.type === 'purchases' && transaction?.transactionPoint === 'produce'">
                            <div class="pt3 pb2 b">Raw Materials</div>
                            <div v-for="(data, index) in transaction.input.rawMaterials" :key="index" class="flex justify-between pv2 box-border-bottom f5">
                                <div>{{ data.name }} ({{ data.quantity }})</div>
                                <div>{{ data.amount }}</div>
                            </div>

                            <div class="pt3 pb2 b">Expenses</div>
                            <div v-for="(data, index) in transaction.expenses.items" :key="index" class="flex justify-between pv2 box-border-bottom f5">
                                <div>{{ data.name }}</div>
                                <div>{{ data.amount }}</div>
                            </div>
                        </div>

                        <div class="flex items-center justify-between pv3 box-border-bottom" v-if="transaction?.address1">
                            <div>Shipping</div>
                            <div class="w-50-l w-70 tr lh-copy">{{ transaction?.address1 }}</div>
                        </div>
                        <div class="flex items-center justify-between pv3 box-border-bottom" v-if="transaction?.entityData">
                            <div>Billed to</div>
                            <div class="w-50-l w-70 tr">
                                <!-- <div class="font-w2 pb1">
                                    {{
                                        `${transaction?.entityData?.name} ${transaction?.entityData?.company &&
                                            `(${transaction?.entityData?.company})`}`
                                    }}
                                </div> -->
                                <div class="font-w2 pb1">
                                    {{ transaction?.entityData?.name }}
                                    <span v-if="transaction?.entityData?.company">
                                        ({{ transaction?.entityData?.company }})
                                    </span>
                                </div>
                                <div class="lh-copy">
                                    {{ transaction?.entityData?.address }}
                                </div>
                                <div>{{ transaction?.entityData?.phone }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="transaction.glAccounts" class="flex items-center pv2 pl5-l pr5-l pl2 pr2">
                        <span class="mr1 b">PAYMENT INFO </span>
                        <!-- <span class="ttc">{{ state?.bankName }}</span> -->
                    </div>
                    <!-- column table -->
                    <div
                        class="flex flex-column pa2-l pa2 pl5-l pr5-l pl2 pr2"
                        v-for="account of transaction.glAccounts"
                        v-bind:key="account"
                    >
                        <div class="flex justify-between pv2 box-border-bottom">
                            <div>{{ account.glAccount }}</div>
                            <div>{{ account?.glAmount }}</div>
                        </div>
                    </div>

                    <div v-if="transaction?.credit" class="pl5-l pr5-l pt3">
                        <div class="flex items-center pb4 pv2">
                            <span class="mr1 b">PAYMENT TO </span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Account Name: </span>
                            <span class="ttc">{{ organization?.bankAccountName }}</span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Account No: </span>
                            <span class="ttc">{{ organization?.bankAccountNumber }}</span>
                        </div>
                        <div class="flex items-center pv2">
                            <span class="mr1 b">Bank Name: </span>
                            <span class="ttc">{{ organization?.bankName }}</span>
                        </div>
                        <!-- <div class="flex items-center pv4 box-border-bottom">
                            <span class="mr1">Sort Code: </span>
                            <span class="ttc">{{ state?.sortCode }}</span>
                        </div> -->
                    </div>
                    <div class="flex items-center justify-center pv3 box-border-bottom">
                        <span class="mr1">Staff: </span>
                        <span class="ttc">{{ transaction?.staffName }}</span>
                    </div>
                    <div class="flex items-center justify-center w-50-l w-80 center pb3 pt3">
                        <div class="b pr2" style="color: #132c8c">Powered by:</div>
                        <a href="https://simplebks.com" target="_blank"><img src="../../../assets/images/simple-logo-mobile.svg" alt="" /></a>
                        
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap justify-between items-center" style="border-top: 1px solid #e3e8ee">
                <div class="action-btns ph3-l ph3 pv3">
                    <font-awesome-icon v-if="state.downloading" icon="fas fa-spinner" class="mr-2" />
                    <a v-else href="#" @click.prevent="downloadReceipt" class="mr-2">
                        <img src="../../../assets/images/direct-download.svg" alt="" />
                    </a>
                    <a href="#" @click.prevent="printReceipt"><img src="../../../assets/images/printer.svg" alt=""/></a>
                </div>

                <template v-if="editable">
                    <div class="ph5-l ph4 pv4-l pb0-l pb4">
                        <button class="btn2">Edt</button>
                        <button class="ml2">Confirm</button>
                    </div>
                </template>

                <template v-else><div></div></template>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
/* eslint-disable */
import { computed, reactive, ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import { useStore } from 'vuex'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import Spinner from '@/components/Spinner'

export default {
    name: 'TransactionDetails',
    props: {
        title: String,
        edit: Function,
        confirm: Function,
        editable: Boolean,
        back: Boolean
    },
    components: { Spinner },

    data() {
        return {
            pdfContent: '',
            pdfFileName: ''
        }
    },

    setup() {
        const route = useRoute()
        const store = useStore()
        const organization = computed(() => store?.state?.Receipt?.organization)
        const orgInitials = computed(() => store?.state?.Receipt?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Receipt?.userInitials)
        const userData = computed(() => store.state?.Receipt?.user)
        const transaction = computed(() => store.state?.Receipt?.transaction)
        const hasDescription = computed(() => Object.keys(transaction).includes('description'))
        const shareUrl = computed(
            () =>
                `${process.env.VUE_APP_BASE_URL}/${store?.state?.Receipt?.organization?._id}/${store?.state?.Receipt?.user?._id}/transaction/${store?.state?.Receipt?.transaction?.reference}/receipt`
        )

        const state = reactive({
            newPDF: null,
            canvas: null,
            height: null,
            bankName: '',
            bankAccountName: '',
            bankAccountNumber: '',
            sortCode: '',
            downloading: false
        })

        const generatePDF = async () => {
            try {
                const receiptUrl = `${process.env.VUE_APP_BASE_URL}/${store?.state?.Receipt?.organization?._id}/${store?.state?.Receipt?.user?._id}/transaction/${store?.state?.Receipt?.transaction?.reference}/view`
                // const fetchResponse = await fetch(receiptUrl);
                const fetchResponse = fetch(receiptUrl)
                    .then(response => response.text())
                    .then(data => {
                        console.log('data: ', data)
                        return data
                    })
                console.log('fetchResponse: ', fetchResponse)
            } catch (ex) {
                console.log(ex)
            }
        }

        const copyShareLink = url => {
            copyText(url, undefined, (error, event) => {
                if (error) {
                    store.dispatch('Alert/setAlert', {
                        message: `Could not copy receipt link`,
                        status: true
                    })
                    console.log(error)
                } else {
                    store.dispatch('Alert/setAlert', {
                        message: `Receipt link copied`,
                        status: true
                    })
                    console.log(event)
                }
            })
        }

        const downloadReceipt = async () => {
            state.downloading = true
            const { transactionRef: reference, orgId, userId } = route.params
            const data = { reference, orgId, userId }
            store.dispatch('Transaction/getTransactionReceiptPdf', data).then(response => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                fileLink.setAttribute('download', `transaction-${data.reference}.pdf`)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                state.downloading = false
            })
        }

        onMounted(() => {
            const orgId = route?.params?.orgId
            const userId = route?.params?.userId
            const transactionRef = route?.params?.transactionRef
            store.dispatch('Receipt/getOrganizationTransaction', { orgId, userId, transactionRef })
        })

        return {
            state,
            transaction,
            organization,
            orgInitials,
            userInitials,
            generatePDF,
            orgImage,
            hasDescription,
            shareUrl,
            copyShareLink,
            downloadReceipt
        }
    },

    mounted() {
        const content = this.$htmlToPdf('receiptContainer')
        this.pdfContent = content
    },

    methods: {
        printReceipt() {
            this.$htmlToPaper('receiptContainer')
        }
    }
}
</script>

<style>
.mr-2 {
    margin-right: 1rem;
}
.share-btn-mobile {
    display: none;
}
.share-btn-desktop {
    display: inline;
}
@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }
    .share-btn-desktop {
        display: none;
    }
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.fa-spinner {
    animation: spinner 1s linear infinite;
}
</style>